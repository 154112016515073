<template>
  <div>
    <page-header>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never" class="card-alert">
        <el-alert type="info" :closable="false">
          <template slot="title">
            <p>账号转移：将原有微信账号下的数据（VIP时长、视频模版、视频、文章、海报等）转移到新的微信账号下，如果原账号是VIP，则降为普通用户，仍可访问系统。</p>
            <p>操作步骤：</p>
            <p>1、先让用户用新的微信账号去公众号注册，并绑定手机号</p>
            <p>2、员工在会员列表页面，进行用户绑定</p>
            <p>3、在账号转移页面，选择旧用户和新用户进行账号转移申请</p>
          </template>
        </el-alert>
      </el-card>
      <el-card shadow="never">
        <el-row>
          <el-col>
            <el-form :model="editForm" :rules="rules" ref="editForm" label-width="200px">
              <el-form-item label="旧用户" prop="oldUserId">
                <remote-select v-model="editForm.oldUserId"
                               width="380"
                               width-input="400px"
                               placement="bottom"
                               url="/user/list"
                               :columns="[{field:'nickName',name:'昵称'},{field:'mobile',name:'手机号'}]"
                               label="nickName"
                               value-key="id"
                               :size="5"
                               clearable></remote-select>
              </el-form-item>
              <el-form-item label="新用户" prop="newUserId">
                <remote-select v-model="editForm.newUserId"
                               width="380"
                               width-input="400px"
                               placement="bottom"
                               url="/user/list"
                               :columns="[{field:'nickName',name:'昵称'},{field:'mobile',name:'手机号'}]"
                               label="nickName"
                               value-key="id"
                               :size="5"
                               clearable></remote-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleSubmit" :loading="loadingSubmit">提交申请</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { UserMigrateLogApi } from '@/api'

export default {
  name: 'UserMigrate',
  data () {
    return {
      editForm: {
        oldUserId: '',
        newUserId: ''
      },
      rules: {
        oldUserId: [
          {
            required: true,
            message: '请选择旧用户',
            trigger: 'change'
          }
        ],
        newUserId: [
          {
            required: true,
            message: '请选择新用户',
            trigger: 'change'
          }
        ]
      },
      loadingSubmit: false
    }
  },
  methods: {
    handleSubmit () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          if (this.editForm.newUserId === this.editForm.oldUserId) {
            this.$message.error('新旧用户不能是同一个用户')
            return
          }

          this.$confirm('确定要执行该操作吗', '提示', {
            type: 'warning'
          }).then(() => {
            this.loadingSubmit = true
            UserMigrateLogApi.apply(this.editForm).then(() => {
              this.loadingSubmit = false
              this.$message.success('申请成功，请等待管理员审核')
            }).catch(() => {
              this.loadingSubmit = false
            })
          })
        } else {
          return false
        }
      })
    }
  },
  created: function () {
  }
}
</script>

<style lang="less">
</style>
